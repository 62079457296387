.inphinity-loader {
    position: relative;
    display: block;
    width: 65px;
    height: 28px;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
}

.inphinity-loader > * {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 3px;
    height: 3px;
    background-color: #4286f4;
    border-radius: 50%;
    content: "";
    offset-path: path("M23.71 5.21l17.58 17.58a12.43 12.43 0 0 0 17.57 0 12.43 12.43 0 0 0 0-17.57 12.43 12.43 0 0 0-17.57 0L23.71 22.79a12.43 12.43 0 0 1-17.57 0 12.43 12.43 0 0 1 0-17.57 12.43 12.43 0 0 1 17.57-.01z");
    animation: inphinity-loader 1500ms linear infinite;
    opacity: 0;
}

.inphinity-loader > *:nth-child(1) {
    animation-delay: 100ms;
}

.inphinity-loader > *:nth-child(2) {
    animation-delay: 200ms;
}

.inphinity-loader > *:nth-child(3) {
    animation-delay: 300ms;
}

.inphinity-loader > *:nth-child(4) {
    animation-delay: 400ms;
}

.inphinity-loader > *:nth-child(5) {
    animation-delay: 500ms;
}

.inphinity-loader > *:nth-child(6) {
    animation-delay: 600ms;
}

.inphinity-loader > *:nth-child(7) {
    animation-delay: 700ms;
}

.inphinity-loader > *:nth-child(8) {
    animation-delay: 800ms;
}

.inphinity-loader > *:nth-child(9) {
    animation-delay: 900ms;
}

.inphinity-loader > *:nth-child(10) {
    animation-delay: 1000ms;
}

.inphinity-loader > *:nth-child(11) {
    animation-delay: 1100ms;
}

.inphinity-loader > *:nth-child(12) {
    animation-delay: 1200ms;
}

.inphinity-loader > *:nth-child(13) {
    animation-delay: 1300ms;
}

.inphinity-loader > *:nth-child(14) {
    animation-delay: 1400ms;
}

.inphinity-loader > *:nth-child(15) {
    animation-delay: 1500ms;
}

@keyframes inphinity-loader {
    0% {
        offset-distance: 0%;
        opacity: 0.5;
    }
    100% {
        offset-distance: 100%;
        opacity: 1;
    }
}