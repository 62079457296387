@font-face {
    font-family: 'material-icons';
    src: url('fonts/material-icons.ttf?rnn6yx') format('truetype'),
    url('fonts/material-icons.woff?rnn6yx') format('woff'),
    url('fonts/material-icons.svg?rnn6yx#material-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="mi-"], [class*=" mi-"] {
    /* Use !important to prevent issues with browser extensions that change fonts */
    font-family: 'material-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    min-width: 1em;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    vertical-align: middle;
    position: relative;
    top: -1px;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mi-2x {
    font-size: 32px;
}

.mi-3x {
    font-size: 48px;
}


.mi-3d-rotation:before {
    content: "\e900";
}

.mi-ac-unit:before {
    content: "\e901";
}

.mi-alarm:before {
    content: "\e902";
}

.mi-access-alarms:before {
    content: "\e903";
}

.mi-schedule:before {
    content: "\e904";
}

.mi-accessibility:before {
    content: "\e905";
}

.mi-accessible:before {
    content: "\e906";
}

.mi-account-balance:before {
    content: "\e907";
}

.mi-account-balance-wallet:before {
    content: "\e908";
}

.mi-account-box:before {
    content: "\e909";
}

.mi-account-circle:before {
    content: "\e90a";
}

.mi-adb:before {
    content: "\e90b";
}

.mi-add:before {
    content: "\e90c";
}

.mi-add-a-photo:before {
    content: "\e90d";
}

.mi-alarm-add:before {
    content: "\e90e";
}

.mi-add-alert:before {
    content: "\e90f";
}

.mi-add-box:before {
    content: "\e910";
}

.mi-add-circle:before {
    content: "\e911";
}

.mi-control-point:before {
    content: "\e912";
}

.mi-add-location:before {
    content: "\e913";
}

.mi-add-shopping-cart:before {
    content: "\e914";
}

.mi-queue:before {
    content: "\e915";
}

.mi-add-to-queue:before {
    content: "\e916";
}

.mi-adjust:before {
    content: "\e917";
}

.mi-airline-seat-flat:before {
    content: "\e918";
}

.mi-airline-seat-flat-angled:before {
    content: "\e919";
}

.mi-airline-seat-individual-suite:before {
    content: "\e91a";
}

.mi-airline-seat-legroom-extra:before {
    content: "\e91b";
}

.mi-airline-seat-legroom-normal:before {
    content: "\e91c";
}

.mi-airline-seat-legroom-reduced:before {
    content: "\e91d";
}

.mi-airline-seat-recline-extra:before {
    content: "\e91e";
}

.mi-airline-seat-recline-normal:before {
    content: "\e91f";
}

.mi-flight:before {
    content: "\e920";
}

.mi-airplanemode-inactive:before {
    content: "\e921";
}

.mi-airplay:before {
    content: "\e922";
}

.mi-airport-shuttle:before {
    content: "\e923";
}

.mi-alarm-off:before {
    content: "\e924";
}

.mi-alarm-on:before {
    content: "\e925";
}

.mi-album:before {
    content: "\e926";
}

.mi-all-inclusive:before {
    content: "\e927";
}

.mi-all-out:before {
    content: "\e928";
}

.mi-android:before {
    content: "\e929";
}

.mi-announcement:before {
    content: "\e92a";
}

.mi-apps:before {
    content: "\e92b";
}

.mi-archive:before {
    content: "\e92c";
}

.mi-arrow-back:before {
    content: "\e92d";
}

.mi-arrow-downward:before {
    content: "\e92e";
}

.mi-arrow-drop-down:before {
    content: "\e92f";
}

.mi-arrow-drop-down-circle:before {
    content: "\e930";
}

.mi-arrow-drop-up:before {
    content: "\e931";
}

.mi-arrow-forward:before {
    content: "\e932";
}

.mi-arrow-upward:before {
    content: "\e933";
}

.mi-art-track:before {
    content: "\e934";
}

.mi-aspect-ratio:before {
    content: "\e935";
}

.mi-poll:before {
    content: "\e936";
}

.mi-assignment:before {
    content: "\e937";
}

.mi-assignment-ind:before {
    content: "\e938";
}

.mi-assignment-late:before {
    content: "\e939";
}

.mi-assignment-return:before {
    content: "\e93a";
}

.mi-assignment-returned:before {
    content: "\e93b";
}

.mi-assignment-turned-in:before {
    content: "\e93c";
}

.mi-assistant:before {
    content: "\e93d";
}

.mi-flag:before {
    content: "\e93e";
}

.mi-attach-file:before {
    content: "\e93f";
}

.mi-attach-money:before {
    content: "\e940";
}

.mi-attachment:before {
    content: "\e941";
}

.mi-audiotrack:before {
    content: "\e942";
}

.mi-autorenew:before {
    content: "\e943";
}

.mi-av-timer:before {
    content: "\e944";
}

.mi-backspace:before {
    content: "\e945";
}

.mi-cloud-upload:before {
    content: "\e946";
}

.mi-battery-alert:before {
    content: "\e947";
}

.mi-battery-charging-full:before {
    content: "\e948";
}

.mi-battery-std:before {
    content: "\e949";
}

.mi-battery-unknown:before {
    content: "\e94a";
}

.mi-beach-access:before {
    content: "\e94b";
}

.mi-beenhere:before {
    content: "\e94c";
}

.mi-block:before {
    content: "\e94d";
}

.mi-bluetooth:before {
    content: "\e94e";
}

.mi-bluetooth-searching:before {
    content: "\e94f";
}

.mi-bluetooth-connected:before {
    content: "\e950";
}

.mi-bluetooth-disabled:before {
    content: "\e951";
}

.mi-blur-circular:before {
    content: "\e952";
}

.mi-blur-linear:before {
    content: "\e953";
}

.mi-blur-off:before {
    content: "\e954";
}

.mi-blur-on:before {
    content: "\e955";
}

.mi-class:before {
    content: "\e956";
}

.mi-turned-in:before {
    content: "\e957";
}

.mi-turned-in-not:before {
    content: "\e958";
}

.mi-border-all:before {
    content: "\e959";
}

.mi-border-bottom:before {
    content: "\e95a";
}

.mi-border-clear:before {
    content: "\e95b";
}

.mi-border-color:before {
    content: "\e95c";
}

.mi-border-horizontal:before {
    content: "\e95d";
}

.mi-border-inner:before {
    content: "\e95e";
}

.mi-border-left:before {
    content: "\e95f";
}

.mi-border-outer:before {
    content: "\e960";
}

.mi-border-right:before {
    content: "\e961";
}

.mi-border-style:before {
    content: "\e962";
}

.mi-border-top:before {
    content: "\e963";
}

.mi-border-vertical:before {
    content: "\e964";
}

.mi-branding-watermark:before {
    content: "\e965";
}

.mi-brightness-1:before {
    content: "\e966";
}

.mi-brightness-2:before {
    content: "\e967";
}

.mi-brightness-3:before {
    content: "\e968";
}

.mi-brightness-4:before {
    content: "\e969";
}

.mi-brightness-low:before {
    content: "\e96a";
}

.mi-brightness-medium:before {
    content: "\e96b";
}

.mi-brightness-high:before {
    content: "\e96c";
}

.mi-brightness-auto:before {
    content: "\e96d";
}

.mi-broken-image:before {
    content: "\e96e";
}

.mi-brush:before {
    content: "\e96f";
}

.mi-bubble-chart:before {
    content: "\e970";
}

.mi-bug-report:before {
    content: "\e971";
}

.mi-build:before {
    content: "\e972";
}

.mi-burst-mode:before {
    content: "\e973";
}

.mi-domain:before {
    content: "\e974";
}

.mi-business-center:before {
    content: "\e975";
}

.mi-cached:before {
    content: "\e976";
}

.mi-cake:before {
    content: "\e977";
}

.mi-phone:before {
    content: "\e978";
}

.mi-call-end:before {
    content: "\e979";
}

.mi-call-made:before {
    content: "\e97a";
}

.mi-merge-type:before {
    content: "\e97b";
}

.mi-call-missed:before {
    content: "\e97c";
}

.mi-call-missed-outgoing:before {
    content: "\e97d";
}

.mi-call-received:before {
    content: "\e97e";
}

.mi-call-split:before {
    content: "\e97f";
}

.mi-call-to-action:before {
    content: "\e980";
}

.mi-camera:before {
    content: "\e981";
}

.mi-photo-camera:before {
    content: "\e982";
}

.mi-camera-enhance:before {
    content: "\e983";
}

.mi-camera-front:before {
    content: "\e984";
}

.mi-camera-rear:before {
    content: "\e985";
}

.mi-camera-roll:before {
    content: "\e986";
}

.mi-cancel:before {
    content: "\e987";
}

.mi-redeem:before {
    content: "\e988";
}

.mi-card-membership:before {
    content: "\e989";
}

.mi-card-travel:before {
    content: "\e98a";
}

.mi-casino:before {
    content: "\e98b";
}

.mi-cast:before {
    content: "\e98c";
}

.mi-cast-connected:before {
    content: "\e98d";
}

.mi-center-focus-strong:before {
    content: "\e98e";
}

.mi-center-focus-weak:before {
    content: "\e98f";
}

.mi-change-history:before {
    content: "\e990";
}

.mi-chat:before {
    content: "\e991";
}

.mi-chat-bubble:before {
    content: "\e992";
}

.mi-chat-bubble-outline:before {
    content: "\e993";
}

.mi-check:before {
    content: "\e994";
}

.mi-check-box:before {
    content: "\e995";
}

.mi-check-box-outline-blank:before {
    content: "\e996";
}

.mi-check-circle:before {
    content: "\e997";
}

.mi-navigate-before:before {
    content: "\e998";
}

.mi-navigate-next:before {
    content: "\e999";
}

.mi-child-care:before {
    content: "\e99a";
}

.mi-child-friendly:before {
    content: "\e99b";
}

.mi-chrome-reader-mode:before {
    content: "\e99c";
}

.mi-close:before {
    content: "\e99d";
}

.mi-clear-all:before {
    content: "\e99e";
}

.mi-closed-caption:before {
    content: "\e99f";
}

.mi-wb-cloudy:before {
    content: "\e9a0";
}

.mi-cloud-circle:before {
    content: "\e9a1";
}

.mi-cloud-done:before {
    content: "\e9a2";
}

.mi-cloud-download:before {
    content: "\e9a3";
}

.mi-cloud-off:before {
    content: "\e9a4";
}

.mi-cloud-queue:before {
    content: "\e9a5";
}

.mi-code:before {
    content: "\e9a6";
}

.mi-photo-library:before {
    content: "\e9a7";
}

.mi-collections-bookmark:before {
    content: "\e9a8";
}

.mi-palette:before {
    content: "\e9a9";
}

.mi-colorize:before {
    content: "\e9aa";
}

.mi-comment:before {
    content: "\e9ab";
}

.mi-compare:before {
    content: "\e9ac";
}

.mi-compare-arrows:before {
    content: "\e9ad";
}

.mi-laptop:before {
    content: "\e9ae";
}

.mi-confirmation-number:before {
    content: "\e9af";
}

.mi-contact-mail:before {
    content: "\e9b0";
}

.mi-contact-phone:before {
    content: "\e9b1";
}

.mi-contacts:before {
    content: "\e9b2";
}

.mi-content-copy:before {
    content: "\e9b3";
}

.mi-content-cut:before {
    content: "\e9b4";
}

.mi-content-paste:before {
    content: "\e9b5";
}

.mi-control-point-duplicate:before {
    content: "\e9b6";
}

.mi-copyright:before {
    content: "\e9b7";
}

.mi-mode-edit:before {
    content: "\e9b8";
}

.mi-create-new-folder:before {
    content: "\e9b9";
}

.mi-payment:before {
    content: "\e9ba";
}

.mi-crop:before {
    content: "\e9bb";
}

.mi-crop-16-9:before {
    content: "\e9bc";
}

.mi-crop-3-2:before {
    content: "\e9bd";
}

.mi-crop-landscape:before {
    content: "\e9be";
}

.mi-crop-7-5:before {
    content: "\e9bf";
}

.mi-crop-din:before {
    content: "\e9c0";
}

.mi-crop-free:before {
    content: "\e9c1";
}

.mi-crop-original:before {
    content: "\e9c2";
}

.mi-crop-portrait:before {
    content: "\e9c3";
}

.mi-crop-rotate:before {
    content: "\e9c4";
}

.mi-crop-square:before {
    content: "\e9c5";
}

.mi-dashboard:before {
    content: "\e9c6";
}

.mi-data-usage:before {
    content: "\e9c7";
}

.mi-date-range:before {
    content: "\e9c8";
}

.mi-dehaze:before {
    content: "\e9c9";
}

.mi-delete:before {
    content: "\e9ca";
}

.mi-delete-forever:before {
    content: "\e9cb";
}

.mi-delete-sweep:before {
    content: "\e9cc";
}

.mi-description:before {
    content: "\e9cd";
}

.mi-desktop-mac:before {
    content: "\e9ce";
}

.mi-desktop-windows:before {
    content: "\e9cf";
}

.mi-details:before {
    content: "\e9d0";
}

.mi-developer-board:before {
    content: "\e9d1";
}

.mi-developer-mode:before {
    content: "\e9d2";
}

.mi-device-hub:before {
    content: "\e9d3";
}

.mi-phonelink:before {
    content: "\e9d4";
}

.mi-devices-other:before {
    content: "\e9d5";
}

.mi-dialer-sip:before {
    content: "\e9d6";
}

.mi-dialpad:before {
    content: "\e9d7";
}

.mi-directions:before {
    content: "\e9d8";
}

.mi-directions-bike:before {
    content: "\e9d9";
}

.mi-directions-boat:before {
    content: "\e9da";
}

.mi-directions-bus:before {
    content: "\e9db";
}

.mi-directions-car:before {
    content: "\e9dc";
}

.mi-directions-railway:before {
    content: "\e9dd";
}

.mi-directions-run:before {
    content: "\e9de";
}

.mi-directions-transit:before {
    content: "\e9df";
}

.mi-directions-walk:before {
    content: "\e9e0";
}

.mi-disc-full:before {
    content: "\e9e1";
}

.mi-dns:before {
    content: "\e9e2";
}

.mi-not-interested:before {
    content: "\e9e3";
}

.mi-do-not-disturb-alt:before {
    content: "\e9e4";
}

.mi-do-not-disturb-off:before {
    content: "\e9e5";
}

.mi-remove-circle:before {
    content: "\e9e6";
}

.mi-dock:before {
    content: "\e9e7";
}

.mi-done:before {
    content: "\e9e8";
}

.mi-done-all:before {
    content: "\e9e9";
}

.mi-donut-large:before {
    content: "\e9ea";
}

.mi-donut-small:before {
    content: "\e9eb";
}

.mi-drafts:before {
    content: "\e9ec";
}

.mi-drag-handle:before {
    content: "\e9ed";
}

.mi-time-to-leave:before {
    content: "\e9ee";
}

.mi-dvr:before {
    content: "\e9ef";
}

.mi-edit-location:before {
    content: "\e9f0";
}

.mi-eject:before {
    content: "\e9f1";
}

.mi-markunread:before {
    content: "\e9f2";
}

.mi-enhanced-encryption:before {
    content: "\e9f3";
}

.mi-equalizer:before {
    content: "\e9f4";
}

.mi-error:before {
    content: "\e9f5";
}

.mi-error-outline:before {
    content: "\e9f6";
}

.mi-euro-symbol:before {
    content: "\e9f7";
}

.mi-ev-station:before {
    content: "\e9f8";
}

.mi-insert-invitation:before {
    content: "\e9f9";
}

.mi-event-available:before {
    content: "\e9fa";
}

.mi-event-busy:before {
    content: "\e9fb";
}

.mi-event-note:before {
    content: "\e9fc";
}

.mi-event-seat:before {
    content: "\e9fd";
}

.mi-exit-to-app:before {
    content: "\e9fe";
}

.mi-expand-less:before {
    content: "\e9ff";
}

.mi-expand-more:before {
    content: "\ea00";
}

.mi-explicit:before {
    content: "\ea01";
}

.mi-explore:before {
    content: "\ea02";
}

.mi-exposure:before {
    content: "\ea03";
}

.mi-exposure-neg-1:before {
    content: "\ea04";
}

.mi-exposure-neg-2:before {
    content: "\ea05";
}

.mi-exposure-plus-1:before {
    content: "\ea06";
}

.mi-exposure-plus-2:before {
    content: "\ea07";
}

.mi-exposure-zero:before {
    content: "\ea08";
}

.mi-extension:before {
    content: "\ea09";
}

.mi-face:before {
    content: "\ea0a";
}

.mi-fast-forward:before {
    content: "\ea0b";
}

.mi-fast-rewind:before {
    content: "\ea0c";
}

.mi-favorite:before {
    content: "\ea0d";
}

.mi-favorite-border:before {
    content: "\ea0e";
}

.mi-featured-play-list:before {
    content: "\ea0f";
}

.mi-featured-video:before {
    content: "\ea10";
}

.mi-sms-failed:before {
    content: "\ea11";
}

.mi-fiber-dvr:before {
    content: "\ea12";
}

.mi-fiber-manual-record:before {
    content: "\ea13";
}

.mi-fiber-new:before {
    content: "\ea14";
}

.mi-fiber-pin:before {
    content: "\ea15";
}

.mi-fiber-smart-record:before {
    content: "\ea16";
}

.mi-get-app:before {
    content: "\ea17";
}

.mi-file-upload:before {
    content: "\ea18";
}

.mi-filter:before {
    content: "\ea19";
}

.mi-filter-1:before {
    content: "\ea1a";
}

.mi-filter-2:before {
    content: "\ea1b";
}

.mi-filter-3:before {
    content: "\ea1c";
}

.mi-filter-4:before {
    content: "\ea1d";
}

.mi-filter-5:before {
    content: "\ea1e";
}

.mi-filter-6:before {
    content: "\ea1f";
}

.mi-filter-7:before {
    content: "\ea20";
}

.mi-filter-8:before {
    content: "\ea21";
}

.mi-filter-9:before {
    content: "\ea22";
}

.mi-filter-9-plus:before {
    content: "\ea23";
}

.mi-filter-b-and-w:before {
    content: "\ea24";
}

.mi-filter-center-focus:before {
    content: "\ea25";
}

.mi-filter-drama:before {
    content: "\ea26";
}

.mi-filter-frames:before {
    content: "\ea27";
}

.mi-terrain:before {
    content: "\ea28";
}

.mi-filter-list:before {
    content: "\ea29";
}

.mi-filter-none:before {
    content: "\ea2a";
}

.mi-filter-tilt-shift:before {
    content: "\ea2b";
}

.mi-filter-vintage:before {
    content: "\ea2c";
}

.mi-find-in-page:before {
    content: "\ea2d";
}

.mi-find-replace:before {
    content: "\ea2e";
}

.mi-fingerprint:before {
    content: "\ea2f";
}

.mi-first-page:before {
    content: "\ea30";
}

.mi-fitness-center:before {
    content: "\ea31";
}

.mi-flare:before {
    content: "\ea32";
}

.mi-flash-auto:before {
    content: "\ea33";
}

.mi-flash-off:before {
    content: "\ea34";
}

.mi-flash-on:before {
    content: "\ea35";
}

.mi-flight-land:before {
    content: "\ea36";
}

.mi-flight-takeoff:before {
    content: "\ea37";
}

.mi-flip:before {
    content: "\ea38";
}

.mi-flip-to-back:before {
    content: "\ea39";
}

.mi-flip-to-front:before {
    content: "\ea3a";
}

.mi-folder:before {
    content: "\ea3b";
}

.mi-folder-open:before {
    content: "\ea3c";
}

.mi-folder-shared:before {
    content: "\ea3d";
}

.mi-folder-special:before {
    content: "\ea3e";
}

.mi-font-download:before {
    content: "\ea3f";
}

.mi-format-align-center:before {
    content: "\ea40";
}

.mi-format-align-justify:before {
    content: "\ea41";
}

.mi-format-align-left:before {
    content: "\ea42";
}

.mi-format-align-right:before {
    content: "\ea43";
}

.mi-format-bold:before {
    content: "\ea44";
}

.mi-format-clear:before {
    content: "\ea45";
}

.mi-format-color-fill:before {
    content: "\ea46";
}

.mi-format-color-reset:before {
    content: "\ea47";
}

.mi-format-color-text:before {
    content: "\ea48";
}

.mi-format-indent-decrease:before {
    content: "\ea49";
}

.mi-format-indent-increase:before {
    content: "\ea4a";
}

.mi-format-italic:before {
    content: "\ea4b";
}

.mi-format-line-spacing:before {
    content: "\ea4c";
}

.mi-format-list-bulleted:before {
    content: "\ea4d";
}

.mi-format-list-numbered:before {
    content: "\ea4e";
}

.mi-format-paint:before {
    content: "\ea4f";
}

.mi-format-quote:before {
    content: "\ea50";
}

.mi-format-shapes:before {
    content: "\ea51";
}

.mi-format-size:before {
    content: "\ea52";
}

.mi-format-strikethrough:before {
    content: "\ea53";
}

.mi-format-textdirection-l-to-r:before {
    content: "\ea54";
}

.mi-format-textdirection-r-to-l:before {
    content: "\ea55";
}

.mi-format-underlined:before {
    content: "\ea56";
}

.mi-question-answer:before {
    content: "\ea57";
}

.mi-forward:before {
    content: "\ea58";
}

.mi-forward-10:before {
    content: "\ea59";
}

.mi-forward-30:before {
    content: "\ea5a";
}

.mi-forward-5:before {
    content: "\ea5b";
}

.mi-free-breakfast:before {
    content: "\ea5c";
}

.mi-fullscreen:before {
    content: "\ea5d";
}

.mi-fullscreen-exit:before {
    content: "\ea5e";
}

.mi-functions:before {
    content: "\ea5f";
}

.mi-g-translate:before {
    content: "\ea60";
}

.mi-games:before {
    content: "\ea61";
}

.mi-gavel:before {
    content: "\ea62";
}

.mi-gesture:before {
    content: "\ea63";
}

.mi-gif:before {
    content: "\ea64";
}

.mi-goat:before {
    content: "\ea65";
}

.mi-golf-course:before {
    content: "\ea66";
}

.mi-my-location:before {
    content: "\ea67";
}

.mi-location-searching:before {
    content: "\ea68";
}

.mi-location-disabled:before {
    content: "\ea69";
}

.mi-star:before {
    content: "\ea6a";
}

.mi-gradient:before {
    content: "\ea6b";
}

.mi-grain:before {
    content: "\ea6c";
}

.mi-graphic-eq:before {
    content: "\ea6d";
}

.mi-grid-off:before {
    content: "\ea6e";
}

.mi-grid-on:before {
    content: "\ea6f";
}

.mi-people:before {
    content: "\ea70";
}

.mi-group-add:before {
    content: "\ea71";
}

.mi-group-work:before {
    content: "\ea72";
}

.mi-hd:before {
    content: "\ea73";
}

.mi-hdr-off:before {
    content: "\ea74";
}

.mi-hdr-on:before {
    content: "\ea75";
}

.mi-hdr-strong:before {
    content: "\ea76";
}

.mi-hdr-weak:before {
    content: "\ea77";
}

.mi-headset:before {
    content: "\ea78";
}

.mi-headset-mic:before {
    content: "\ea79";
}

.mi-healing:before {
    content: "\ea7a";
}

.mi-hearing:before {
    content: "\ea7b";
}

.mi-help:before {
    content: "\ea7c";
}

.mi-help-outline:before {
    content: "\ea7d";
}

.mi-high-quality:before {
    content: "\ea7e";
}

.mi-highlight:before {
    content: "\ea7f";
}

.mi-highlight-off:before {
    content: "\ea80";
}

.mi-restore:before {
    content: "\ea81";
}

.mi-home:before {
    content: "\ea82";
}

.mi-hot-tub:before {
    content: "\ea83";
}

.mi-local-hotel:before {
    content: "\ea84";
}

.mi-hourglass-empty:before {
    content: "\ea85";
}

.mi-hourglass-full:before {
    content: "\ea86";
}

.mi-http:before {
    content: "\ea87";
}

.mi-lock:before {
    content: "\ea88";
}

.mi-photo:before {
    content: "\ea89";
}

.mi-image-aspect-ratio:before {
    content: "\ea8a";
}

.mi-import-contacts:before {
    content: "\ea8b";
}

.mi-import-export:before {
    content: "\ea8c";
}

.mi-important-devices:before {
    content: "\ea8d";
}

.mi-inbox:before {
    content: "\ea8e";
}

.mi-indeterminate-check-box:before {
    content: "\ea8f";
}

.mi-info:before {
    content: "\ea90";
}

.mi-info-outline:before {
    content: "\ea91";
}

.mi-input:before {
    content: "\ea92";
}

.mi-insert-comment:before {
    content: "\ea93";
}

.mi-insert-drive-file:before {
    content: "\ea94";
}

.mi-tag-faces:before {
    content: "\ea95";
}

.mi-link:before {
    content: "\ea96";
}

.mi-invert-colors:before {
    content: "\ea97";
}

.mi-invert-colors-off:before {
    content: "\ea98";
}

.mi-iso:before {
    content: "\ea99";
}

.mi-keyboard:before {
    content: "\ea9a";
}

.mi-keyboard-arrow-down:before {
    content: "\ea9b";
}

.mi-keyboard-arrow-left:before {
    content: "\ea9c";
}

.mi-keyboard-arrow-right:before {
    content: "\ea9d";
}

.mi-keyboard-arrow-up:before {
    content: "\ea9e";
}

.mi-keyboard-backspace:before {
    content: "\ea9f";
}

.mi-keyboard-capslock:before {
    content: "\eaa0";
}

.mi-keyboard-hide:before {
    content: "\eaa1";
}

.mi-keyboard-return:before {
    content: "\eaa2";
}

.mi-keyboard-tab:before {
    content: "\eaa3";
}

.mi-keyboard-voice:before {
    content: "\eaa4";
}

.mi-kitchen:before {
    content: "\eaa5";
}

.mi-label:before {
    content: "\eaa6";
}

.mi-label-outline:before {
    content: "\eaa7";
}

.mi-language:before {
    content: "\eaa8";
}

.mi-laptop-chromebook:before {
    content: "\eaa9";
}

.mi-laptop-mac:before {
    content: "\eaaa";
}

.mi-laptop-windows:before {
    content: "\eaab";
}

.mi-last-page:before {
    content: "\eaac";
}

.mi-open-in-new:before {
    content: "\eaad";
}

.mi-layers:before {
    content: "\eaae";
}

.mi-layers-clear:before {
    content: "\eaaf";
}

.mi-leak-add:before {
    content: "\eab0";
}

.mi-leak-remove:before {
    content: "\eab1";
}

.mi-lens:before {
    content: "\eab2";
}

.mi-library-books:before {
    content: "\eab3";
}

.mi-library-music:before {
    content: "\eab4";
}

.mi-lightbulb-outline:before {
    content: "\eab5";
}

.mi-line-style:before {
    content: "\eab6";
}

.mi-line-weight:before {
    content: "\eab7";
}

.mi-linear-scale:before {
    content: "\eab8";
}

.mi-linked-camera:before {
    content: "\eab9";
}

.mi-list:before {
    content: "\eaba";
}

.mi-live-help:before {
    content: "\eabb";
}

.mi-live-tv:before {
    content: "\eabc";
}

.mi-local-play:before {
    content: "\eabd";
}

.mi-local-airport:before {
    content: "\eabe";
}

.mi-local-atm:before {
    content: "\eabf";
}

.mi-local-bar:before {
    content: "\eac0";
}

.mi-local-cafe:before {
    content: "\eac1";
}

.mi-local-car-wash:before {
    content: "\eac2";
}

.mi-local-convenience-store:before {
    content: "\eac3";
}

.mi-restaurant-menu:before {
    content: "\eac4";
}

.mi-local-drink:before {
    content: "\eac5";
}

.mi-local-florist:before {
    content: "\eac6";
}

.mi-local-gas-station:before {
    content: "\eac7";
}

.mi-shopping-cart:before {
    content: "\eac8";
}

.mi-local-hospital:before {
    content: "\eac9";
}

.mi-local-laundry-service:before {
    content: "\eaca";
}

.mi-local-library:before {
    content: "\eacb";
}

.mi-local-mall:before {
    content: "\eacc";
}

.mi-theaters:before {
    content: "\eacd";
}

.mi-local-offer:before {
    content: "\eace";
}

.mi-local-parking:before {
    content: "\eacf";
}

.mi-local-pharmacy:before {
    content: "\ead0";
}

.mi-local-pizza:before {
    content: "\ead1";
}

.mi-print:before {
    content: "\ead2";
}

.mi-local-shipping:before {
    content: "\ead3";
}

.mi-local-taxi:before {
    content: "\ead4";
}

.mi-location-city:before {
    content: "\ead5";
}

.mi-location-off:before {
    content: "\ead6";
}

.mi-room:before {
    content: "\ead7";
}

.mi-lock-open:before {
    content: "\ead8";
}

.mi-lock-outline:before {
    content: "\ead9";
}

.mi-looks:before {
    content: "\eada";
}

.mi-looks-3:before {
    content: "\eadb";
}

.mi-looks-4:before {
    content: "\eadc";
}

.mi-looks-5:before {
    content: "\eadd";
}

.mi-looks-6:before {
    content: "\eade";
}

.mi-looks-one:before {
    content: "\eadf";
}

.mi-looks-two:before {
    content: "\eae0";
}

.mi-sync:before {
    content: "\eae1";
}

.mi-loupe:before {
    content: "\eae2";
}

.mi-low-priority:before {
    content: "\eae3";
}

.mi-loyalty:before {
    content: "\eae4";
}

.mi-mail-outline:before {
    content: "\eae5";
}

.mi-map:before {
    content: "\eae6";
}

.mi-markunread-mailbox:before {
    content: "\eae7";
}

.mi-memory:before {
    content: "\eae8";
}

.mi-menu:before {
    content: "\eae9";
}

.mi-message:before {
    content: "\eaea";
}

.mi-mic:before {
    content: "\eaeb";
}

.mi-mic-none:before {
    content: "\eaec";
}

.mi-mic-off:before {
    content: "\eaed";
}

.mi-mms:before {
    content: "\eaee";
}

.mi-mode-comment:before {
    content: "\eaef";
}

.mi-monetization-on:before {
    content: "\eaf0";
}

.mi-money-off:before {
    content: "\eaf1";
}

.mi-monochrome-photos:before {
    content: "\eaf2";
}

.mi-mood-bad:before {
    content: "\eaf3";
}

.mi-more:before {
    content: "\eaf4";
}

.mi-more-horiz:before {
    content: "\eaf5";
}

.mi-more-vert:before {
    content: "\eaf6";
}

.mi-motorcycle:before {
    content: "\eaf7";
}

.mi-mouse:before {
    content: "\eaf8";
}

.mi-move-to-inbox:before {
    content: "\eaf9";
}

.mi-movie-creation:before {
    content: "\eafa";
}

.mi-movie-filter:before {
    content: "\eafb";
}

.mi-multiline-chart:before {
    content: "\eafc";
}

.mi-music-note:before {
    content: "\eafd";
}

.mi-music-video:before {
    content: "\eafe";
}

.mi-nature:before {
    content: "\eaff";
}

.mi-nature-people:before {
    content: "\eb00";
}

.mi-navigation:before {
    content: "\eb01";
}

.mi-near-me:before {
    content: "\eb02";
}

.mi-network-cell:before {
    content: "\eb03";
}

.mi-network-check:before {
    content: "\eb04";
}

.mi-network-locked:before {
    content: "\eb05";
}

.mi-network-wifi:before {
    content: "\eb06";
}

.mi-new-releases:before {
    content: "\eb07";
}

.mi-next-week:before {
    content: "\eb08";
}

.mi-nfc:before {
    content: "\eb09";
}

.mi-no-encryption:before {
    content: "\eb0a";
}

.mi-signal-cellular-no-sim:before {
    content: "\eb0b";
}

.mi-note:before {
    content: "\eb0c";
}

.mi-note-add:before {
    content: "\eb0d";
}

.mi-notifications:before {
    content: "\eb0e";
}

.mi-notifications-active:before {
    content: "\eb0f";
}

.mi-notifications-none:before {
    content: "\eb10";
}

.mi-notifications-off:before {
    content: "\eb11";
}

.mi-notifications-paused:before {
    content: "\eb12";
}

.mi-offline-pin:before {
    content: "\eb13";
}

.mi-ondemand-video:before {
    content: "\eb14";
}

.mi-opacity:before {
    content: "\eb15";
}

.mi-open-in-browser:before {
    content: "\eb16";
}

.mi-open-with:before {
    content: "\eb17";
}

.mi-pages:before {
    content: "\eb18";
}

.mi-pageview:before {
    content: "\eb19";
}

.mi-pan-tool:before {
    content: "\eb1a";
}

.mi-panorama:before {
    content: "\eb1b";
}

.mi-radio-button-unchecked:before {
    content: "\eb1c";
}

.mi-panorama-horizontal:before {
    content: "\eb1d";
}

.mi-panorama-vertical:before {
    content: "\eb1e";
}

.mi-panorama-wide-angle:before {
    content: "\eb1f";
}

.mi-party-mode:before {
    content: "\eb20";
}

.mi-pause:before {
    content: "\eb21";
}

.mi-pause-circle-filled:before {
    content: "\eb22";
}

.mi-pause-circle-outline:before {
    content: "\eb23";
}

.mi-people-outline:before {
    content: "\eb24";
}

.mi-perm-camera-mic:before {
    content: "\eb25";
}

.mi-perm-contact-calendar:before {
    content: "\eb26";
}

.mi-perm-data-setting:before {
    content: "\eb27";
}

.mi-perm-device-information:before {
    content: "\eb28";
}

.mi-person-outline:before {
    content: "\eb29";
}

.mi-perm-media:before {
    content: "\eb2a";
}

.mi-perm-phone-msg:before {
    content: "\eb2b";
}

.mi-perm-scan-wifi:before {
    content: "\eb2c";
}

.mi-person:before {
    content: "\eb2d";
}

.mi-person-add:before {
    content: "\eb2e";
}

.mi-person-pin:before {
    content: "\eb2f";
}

.mi-person-pin-circle:before {
    content: "\eb30";
}

.mi-personal-video:before {
    content: "\eb31";
}

.mi-pets:before {
    content: "\eb32";
}

.mi-phone-android:before {
    content: "\eb33";
}

.mi-phone-bluetooth-speaker:before {
    content: "\eb34";
}

.mi-phone-forwarded:before {
    content: "\eb35";
}

.mi-phone-in-talk:before {
    content: "\eb36";
}

.mi-phone-iphone:before {
    content: "\eb37";
}

.mi-phone-locked:before {
    content: "\eb38";
}

.mi-phone-missed:before {
    content: "\eb39";
}

.mi-phone-paused:before {
    content: "\eb3a";
}

.mi-phonelink-erase:before {
    content: "\eb3b";
}

.mi-phonelink-lock:before {
    content: "\eb3c";
}

.mi-phonelink-off:before {
    content: "\eb3d";
}

.mi-phonelink-ring:before {
    content: "\eb3e";
}

.mi-phonelink-setup:before {
    content: "\eb3f";
}

.mi-photo-album:before {
    content: "\eb40";
}

.mi-photo-filter:before {
    content: "\eb41";
}

.mi-photo-size-select-actual:before {
    content: "\eb42";
}

.mi-photo-size-select-large:before {
    content: "\eb43";
}

.mi-photo-size-select-small:before {
    content: "\eb44";
}

.mi-picture-as-pdf:before {
    content: "\eb45";
}

.mi-picture-in-picture:before {
    content: "\eb46";
}

.mi-picture-in-picture-alt:before {
    content: "\eb47";
}

.mi-pie-chart:before {
    content: "\eb48";
}

.mi-pie-chart-outlined:before {
    content: "\eb49";
}

.mi-pin-drop:before {
    content: "\eb4a";
}

.mi-play-arrow:before {
    content: "\eb4b";
}

.mi-play-circle-filled:before {
    content: "\eb4c";
}

.mi-play-circle-outline:before {
    content: "\eb4d";
}

.mi-play-for-work:before {
    content: "\eb4e";
}

.mi-playlist-add:before {
    content: "\eb4f";
}

.mi-playlist-add-check:before {
    content: "\eb50";
}

.mi-playlist-play:before {
    content: "\eb51";
}

.mi-plus-one:before {
    content: "\eb52";
}

.mi-polymer:before {
    content: "\eb53";
}

.mi-pool:before {
    content: "\eb54";
}

.mi-portable-wifi-off:before {
    content: "\eb55";
}

.mi-portrait:before {
    content: "\eb56";
}

.mi-power:before {
    content: "\eb57";
}

.mi-power-input:before {
    content: "\eb58";
}

.mi-power-settings-new:before {
    content: "\eb59";
}

.mi-pregnant-woman:before {
    content: "\eb5a";
}

.mi-present-to-all:before {
    content: "\eb5b";
}

.mi-priority-high:before {
    content: "\eb5c";
}

.mi-public:before {
    content: "\eb5d";
}

.mi-publish:before {
    content: "\eb5e";
}

.mi-queue-music:before {
    content: "\eb5f";
}

.mi-queue-play-next:before {
    content: "\eb60";
}

.mi-radio:before {
    content: "\eb61";
}

.mi-radio-button-checked:before {
    content: "\eb62";
}

.mi-rate-review:before {
    content: "\eb63";
}

.mi-receipt:before {
    content: "\eb64";
}

.mi-recent-actors:before {
    content: "\eb65";
}

.mi-record-voice-over:before {
    content: "\eb66";
}

.mi-redo:before {
    content: "\eb67";
}

.mi-refresh:before {
    content: "\eb68";
}

.mi-remove:before {
    content: "\eb69";
}

.mi-remove-circle-outline:before {
    content: "\eb6a";
}

.mi-remove-from-queue:before {
    content: "\eb6b";
}

.mi-visibility:before {
    content: "\eb6c";
}

.mi-remove-shopping-cart:before {
    content: "\eb6d";
}

.mi-reorder:before {
    content: "\eb6e";
}

.mi-repeat:before {
    content: "\eb6f";
}

.mi-repeat-one:before {
    content: "\eb70";
}

.mi-replay:before {
    content: "\eb71";
}

.mi-replay-10:before {
    content: "\eb72";
}

.mi-replay-30:before {
    content: "\eb73";
}

.mi-replay-5:before {
    content: "\eb74";
}

.mi-reply:before {
    content: "\eb75";
}

.mi-reply-all:before {
    content: "\eb76";
}

.mi-report:before {
    content: "\eb77";
}

.mi-warning:before {
    content: "\eb78";
}

.mi-restaurant:before {
    content: "\eb79";
}

.mi-restore-page:before {
    content: "\eb7a";
}

.mi-ring-volume:before {
    content: "\eb7b";
}

.mi-room-service:before {
    content: "\eb7c";
}

.mi-rotate-90-degrees-ccw:before {
    content: "\eb7d";
}

.mi-rotate-left:before {
    content: "\eb7e";
}

.mi-rotate-right:before {
    content: "\eb7f";
}

.mi-rounded-corner:before {
    content: "\eb80";
}

.mi-router:before {
    content: "\eb81";
}

.mi-rowing:before {
    content: "\eb82";
}

.mi-rss-feed:before {
    content: "\eb83";
}

.mi-rv-hookup:before {
    content: "\eb84";
}

.mi-satellite:before {
    content: "\eb85";
}

.mi-save:before {
    content: "\eb86";
}

.mi-scanner:before {
    content: "\eb87";
}

.mi-school:before {
    content: "\eb88";
}

.mi-screen-lock-landscape:before {
    content: "\eb89";
}

.mi-screen-lock-portrait:before {
    content: "\eb8a";
}

.mi-screen-lock-rotation:before {
    content: "\eb8b";
}

.mi-screen-rotation:before {
    content: "\eb8c";
}

.mi-screen-share:before {
    content: "\eb8d";
}

.mi-sd-storage:before {
    content: "\eb8e";
}

.mi-search:before {
    content: "\eb8f";
}

.mi-security:before {
    content: "\eb90";
}

.mi-select-all:before {
    content: "\eb91";
}

.mi-send:before {
    content: "\eb92";
}

.mi-sentiment-dissatisfied:before {
    content: "\eb93";
}

.mi-sentiment-neutral:before {
    content: "\eb94";
}

.mi-sentiment-satisfied:before {
    content: "\eb95";
}

.mi-sentiment-very-dissatisfied:before {
    content: "\eb96";
}

.mi-sentiment-very-satisfied:before {
    content: "\eb97";
}

.mi-settings:before {
    content: "\eb98";
}

.mi-settings-applications:before {
    content: "\eb99";
}

.mi-settings-backup-restore:before {
    content: "\eb9a";
}

.mi-settings-bluetooth:before {
    content: "\eb9b";
}

.mi-settings-brightness:before {
    content: "\eb9c";
}

.mi-settings-cell:before {
    content: "\eb9d";
}

.mi-settings-ethernet:before {
    content: "\eb9e";
}

.mi-settings-input-antenna:before {
    content: "\eb9f";
}

.mi-settings-input-composite:before {
    content: "\eba0";
}

.mi-settings-input-hdmi:before {
    content: "\eba1";
}

.mi-settings-input-svideo:before {
    content: "\eba2";
}

.mi-settings-overscan:before {
    content: "\eba3";
}

.mi-settings-phone:before {
    content: "\eba4";
}

.mi-settings-power:before {
    content: "\eba5";
}

.mi-settings-remote:before {
    content: "\eba6";
}

.mi-settings-system-daydream:before {
    content: "\eba7";
}

.mi-settings-voice:before {
    content: "\eba8";
}

.mi-share:before {
    content: "\eba9";
}

.mi-shop:before {
    content: "\ebaa";
}

.mi-shop-two:before {
    content: "\ebab";
}

.mi-shopping-basket:before {
    content: "\ebac";
}

.mi-short-text:before {
    content: "\ebad";
}

.mi-show-chart:before {
    content: "\ebae";
}

.mi-shuffle:before {
    content: "\ebaf";
}

.mi-signal-cellular-4-bar:before {
    content: "\ebb0";
}

.mi-signal-cellular-connected-no-internet-4-bar:before {
    content: "\ebb1";
}

.mi-signal-cellular-null:before {
    content: "\ebb2";
}

.mi-signal-cellular-off:before {
    content: "\ebb3";
}

.mi-signal-wifi-4-bar:before {
    content: "\ebb4";
}

.mi-signal-wifi-4-bar-lock:before {
    content: "\ebb5";
}

.mi-signal-wifi-off:before {
    content: "\ebb6";
}

.mi-sim-card:before {
    content: "\ebb7";
}

.mi-sim-card-alert:before {
    content: "\ebb8";
}

.mi-skip-next:before {
    content: "\ebb9";
}

.mi-skip-previous:before {
    content: "\ebba";
}

.mi-slideshow:before {
    content: "\ebbb";
}

.mi-slow-motion-video:before {
    content: "\ebbc";
}

.mi-stay-primary-portrait:before {
    content: "\ebbd";
}

.mi-smoke-free:before {
    content: "\ebbe";
}

.mi-smoking-rooms:before {
    content: "\ebbf";
}

.mi-textsms:before {
    content: "\ebc0";
}

.mi-snooze:before {
    content: "\ebc1";
}

.mi-sort:before {
    content: "\ebc2";
}

.mi-sort-by-alpha:before {
    content: "\ebc3";
}

.mi-spa:before {
    content: "\ebc4";
}

.mi-space-bar:before {
    content: "\ebc5";
}

.mi-speaker:before {
    content: "\ebc6";
}

.mi-speaker-group:before {
    content: "\ebc7";
}

.mi-speaker-notes:before {
    content: "\ebc8";
}

.mi-speaker-notes-off:before {
    content: "\ebc9";
}

.mi-speaker-phone:before {
    content: "\ebca";
}

.mi-spellcheck:before {
    content: "\ebcb";
}

.mi-star-border:before {
    content: "\ebcc";
}

.mi-star-half:before {
    content: "\ebcd";
}

.mi-stars:before {
    content: "\ebce";
}

.mi-stay-primary-landscape:before {
    content: "\ebcf";
}

.mi-stop:before {
    content: "\ebd0";
}

.mi-stop-screen-share:before {
    content: "\ebd1";
}

.mi-storage:before {
    content: "\ebd2";
}

.mi-store-mall-directory:before {
    content: "\ebd3";
}

.mi-straighten:before {
    content: "\ebd4";
}

.mi-streetview:before {
    content: "\ebd5";
}

.mi-strikethrough-s:before {
    content: "\ebd6";
}

.mi-style:before {
    content: "\ebd7";
}

.mi-subdirectory-arrow-left:before {
    content: "\ebd8";
}

.mi-subdirectory-arrow-right:before {
    content: "\ebd9";
}

.mi-subject:before {
    content: "\ebda";
}

.mi-subscriptions:before {
    content: "\ebdb";
}

.mi-subtitles:before {
    content: "\ebdc";
}

.mi-subway:before {
    content: "\ebdd";
}

.mi-supervisor-account:before {
    content: "\ebde";
}

.mi-surround-sound:before {
    content: "\ebdf";
}

.mi-swap-calls:before {
    content: "\ebe0";
}

.mi-swap-horiz:before {
    content: "\ebe1";
}

.mi-swap-vert:before {
    content: "\ebe2";
}

.mi-swap-vertical-circle:before {
    content: "\ebe3";
}

.mi-switch-camera:before {
    content: "\ebe4";
}

.mi-switch-video:before {
    content: "\ebe5";
}

.mi-sync-disabled:before {
    content: "\ebe6";
}

.mi-sync-problem:before {
    content: "\ebe7";
}

.mi-system-update:before {
    content: "\ebe8";
}

.mi-system-update-alt:before {
    content: "\ebe9";
}

.mi-tab:before {
    content: "\ebea";
}

.mi-tab-unselected:before {
    content: "\ebeb";
}

.mi-tablet:before {
    content: "\ebec";
}

.mi-tablet-android:before {
    content: "\ebed";
}

.mi-tablet-mac:before {
    content: "\ebee";
}

.mi-tap-and-play:before {
    content: "\ebef";
}

.mi-text-fields:before {
    content: "\ebf0";
}

.mi-text-format:before {
    content: "\ebf1";
}

.mi-texture:before {
    content: "\ebf2";
}

.mi-thumb-down:before {
    content: "\ebf3";
}

.mi-thumb-up:before {
    content: "\ebf4";
}

.mi-thumbs-up-down:before {
    content: "\ebf5";
}

.mi-timelapse:before {
    content: "\ebf6";
}

.mi-timeline:before {
    content: "\ebf7";
}

.mi-timer:before {
    content: "\ebf8";
}

.mi-timer-10:before {
    content: "\ebf9";
}

.mi-timer-3:before {
    content: "\ebfa";
}

.mi-timer-off:before {
    content: "\ebfb";
}

.mi-title:before {
    content: "\ebfc";
}

.mi-toc:before {
    content: "\ebfd";
}

.mi-today:before {
    content: "\ebfe";
}

.mi-toll:before {
    content: "\ebff";
}

.mi-tonality:before {
    content: "\ec00";
}

.mi-touch-app:before {
    content: "\ec01";
}

.mi-toys:before {
    content: "\ec02";
}

.mi-track-changes:before {
    content: "\ec03";
}

.mi-traffic:before {
    content: "\ec04";
}

.mi-train:before {
    content: "\ec05";
}

.mi-tram:before {
    content: "\ec06";
}

.mi-transfer-within-a-station:before {
    content: "\ec07";
}

.mi-transform:before {
    content: "\ec08";
}

.mi-translate:before {
    content: "\ec09";
}

.mi-trending-down:before {
    content: "\ec0a";
}

.mi-trending-flat:before {
    content: "\ec0b";
}

.mi-trending-up:before {
    content: "\ec0c";
}

.mi-tune:before {
    content: "\ec0d";
}

.mi-tv:before {
    content: "\ec0e";
}

.mi-unarchive:before {
    content: "\ec0f";
}

.mi-undo:before {
    content: "\ec10";
}

.mi-unfold-less:before {
    content: "\ec11";
}

.mi-unfold-more:before {
    content: "\ec12";
}

.mi-update:before {
    content: "\ec13";
}

.mi-usb:before {
    content: "\ec14";
}

.mi-verified-user:before {
    content: "\ec15";
}

.mi-vertical-align-bottom:before {
    content: "\ec16";
}

.mi-vertical-align-center:before {
    content: "\ec17";
}

.mi-vertical-align-top:before {
    content: "\ec18";
}

.mi-vibration:before {
    content: "\ec19";
}

.mi-video-call:before {
    content: "\ec1a";
}

.mi-video-label:before {
    content: "\ec1b";
}

.mi-video-library:before {
    content: "\ec1c";
}

.mi-videocam:before {
    content: "\ec1d";
}

.mi-videocam-off:before {
    content: "\ec1e";
}

.mi-videogame-asset:before {
    content: "\ec1f";
}

.mi-view-agenda:before {
    content: "\ec20";
}

.mi-view-array:before {
    content: "\ec21";
}

.mi-view-carousel:before {
    content: "\ec22";
}

.mi-view-column:before {
    content: "\ec23";
}

.mi-view-comfy:before {
    content: "\ec24";
}

.mi-view-compact:before {
    content: "\ec25";
}

.mi-view-day:before {
    content: "\ec26";
}

.mi-view-headline:before {
    content: "\ec27";
}

.mi-view-list:before {
    content: "\ec28";
}

.mi-view-module:before {
    content: "\ec29";
}

.mi-view-quilt:before {
    content: "\ec2a";
}

.mi-view-stream:before {
    content: "\ec2b";
}

.mi-view-week:before {
    content: "\ec2c";
}

.mi-vignette:before {
    content: "\ec2d";
}

.mi-visibility-off:before {
    content: "\ec2e";
}

.mi-voice-chat:before {
    content: "\ec2f";
}

.mi-voicemail:before {
    content: "\ec30";
}

.mi-volume-down:before {
    content: "\ec31";
}

.mi-volume-mute:before {
    content: "\ec32";
}

.mi-volume-off:before {
    content: "\ec33";
}

.mi-volume-up:before {
    content: "\ec34";
}

.mi-vpn-key:before {
    content: "\ec35";
}

.mi-vpn-lock:before {
    content: "\ec36";
}

.mi-wallpaper:before {
    content: "\ec37";
}

.mi-watch:before {
    content: "\ec38";
}

.mi-watch-later:before {
    content: "\ec39";
}

.mi-wb-auto:before {
    content: "\ec3a";
}

.mi-wb-incandescent:before {
    content: "\ec3b";
}

.mi-wb-iridescent:before {
    content: "\ec3c";
}

.mi-wb-sunny:before {
    content: "\ec3d";
}

.mi-wc:before {
    content: "\ec3e";
}

.mi-web:before {
    content: "\ec3f";
}

.mi-web-asset:before {
    content: "\ec40";
}

.mi-weekend:before {
    content: "\ec41";
}

.mi-whatshot:before {
    content: "\ec42";
}

.mi-widgets:before {
    content: "\ec43";
}

.mi-wifi:before {
    content: "\ec44";
}

.mi-wifi-lock:before {
    content: "\ec45";
}

.mi-wifi-tethering:before {
    content: "\ec46";
}

.mi-work:before {
    content: "\ec47";
}

.mi-wrap-text:before {
    content: "\ec48";
}

.mi-youtube-searched-for:before {
    content: "\ec49";
}

.mi-zoom-in:before {
    content: "\ec4a";
}

.mi-zoom-out:before {
    content: "\ec4b";
}

.mi-zoom-out-map:before {
    content: "\ec4c";
}
