.bsod {
    background: #0102ac;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.bsod h1 {
    display: inline;
    color: #0102ac;
    background: #cdcdc5;
    font-size: inherit;
    padding-left: 1em;
    padding-right: 1em;
}

.bsod div {
    max-width: 50%;
}

.bsod a {
    text-decoration: underline;
    color: white;
}

.bsod ul {
    list-style: none;
    text-align: left;
    display: inline-block;
}

.bsod ul li:before {
    content: "* "
}