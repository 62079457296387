.page-store {
    background-color: white;
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.page-store-demo {
    background-color: white;
    background-size: 100%, 100% auto;
    background-repeat: repeat, no-repeat;
}

.page-portfolio-demo {
    background: url(/img/background/banner.jpg);
    background-size: cover;
    color: white;
    padding-top: 100px;
    padding-bottom: 0px;
    background-position: center center;
}

.page-portfolio-demo h1 {
    font-size: 40px;
}

.page-store.banner-demo {
    background-image: url("/img/background/banner.jpg");
}

.page-store.banner-1 {
    background-image: url("/images/store/BANNER_1_store.jpg");
}

.page-store.banner-2 {
    background-image: url("/images/store/BANNER_2_store.jpg");
}

.page-store.banner-3 {
    background-image: url("/images/store/BANNER_3_store.jpg");
}

.page-store .banner-header {
    display: none;
    color: white;
    font-size: 25px;
    max-width: 50%;
    height: 155px;
}

.page-store .banner-spacer {
    width: 25%;
}

@media (max-width: 800px) {
    .page-store .banner-header {
        max-width: 96%;
        font-size: 18px;
    }

    .page-store .banner-spacer {
        width: 2%;
    }
}

.page-store.banner-1 .banner-header.banner-1 {
    display: block;
}

.page-store.banner-2 .banner-header.banner-2 {
    display: block;
}

.page-store.banner-3 .banner-header.banner-3 {
    display: block;
}

.page-store.banner-4 .banner-header.banner-4 {
    display: block;
}

.page-store.banner-5 .banner-header.banner-5 {
    display: block;
}

.page-store.banner-6 .banner-header.banner-6 {
    display: block;
}

.page-store.banner-7 .banner-header.banner-7 {
    display: block;
}

.page-store.banner-8 .banner-header.banner-8 {
    display: block;
}

.page-store .banner-header p {
    padding: 0;
    margin: 0;
    margin-top: -10px;
}

.page-store .banner-header p:first-child {
    margin-top: 20px;
    font-weight: bold;
}

.page-store .banner-header a {
    color: white;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

.page-store .banner-header a:hover {
    text-decoration: underline;
}

.wizard.wizard-template-detail .steps li::after,
.wizard.wizard-template-detail .steps li::before {
    background-color: white;
}

.wizard.wizard-template-detail .steps li .number {
    background-color: white;
    color: black;
}

.wizard.wizard-template-detail .steps li.done .number {
    background-color: #b62467;
    border-color: white;
}

.wizard.wizard-template-detail .steps li a, .wizard.wizard-template-detail .steps li.done a, .wizard.wizard-template-detail .steps li.done a:hover {
    color: #b62467;
}

@keyframes phone-shake {
    50%,
    100%,
    from {
        transform: translate3d(0, 0, 0)
    }
    10%, 20%, 30%, 40% {
        transform: translate3d(-2px, 0, 0)
    }
    5%, 15%, 25%, 35%, 45% {
        transform: translate3d(2px, 0, 0)
    }
}

.phone-shake {
    animation-delay: 1s;
    animation-duration: 1s;
    animation-iteration-count: 3;
    animation-fill-mode: both;
    animation-name: phone-shake;
}
