.courses {
    background-color: white;
    background-size: 100%, 100% auto;
    background-repeat: repeat, no-repeat;
}

.courses__title {
    flex-wrap: wrap;
    justify-content: center;
}

.courses__title h1 {
    font-family: "Golos Text", sans-serif;
    font-weight: bold;
    font-size: 3em;
    color: #504148;
    text-transform: uppercase;
}

.course-card {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.course-card i {
    font-size: 20px;
}

.course-card__title, .course-card__time {
    display: flex;
    align-items: center;
}

.course-card__title {
    font-weight: bold;
    gap: 20px;
}

.course-card__title h4 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: #4E0A55;
    transition: 0.3s;
}

.course-card__title h4:hover {
    cursor: pointer;
    color: #7d2487;
}

.course-card__info {
    padding-bottom: 20px;
}

.course-card__info p {
    padding: 0;
}



.course-header {
    display: flex;
    align-items: center;
}

.course-header__title {
    margin: 0;
}

.course-header__share {
    padding-left: 10px;
}

.course-header__time {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.course-header__time i {
    font-size: 20px;
}

.course-lessons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.course-lessons a {
    padding: 5px;
}

.course-lessons p, .course-lessons a {
    cursor: pointer;
    transition: 0.3s;
    color: black;
}

.course-lessons p:hover, .course-lessons a:hover {
    font-weight: bold;
}

.course-lessons i {
    font-size: 20px;
    cursor: pointer;
}

.courses-related {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.courses-related .courses_related__name {
    transition: 0.3s;
}

.courses-related .courses_related__name:hover {
    font-weight: bold;
}

.courses-related__time {
    display: flex;
    align-items: center;
}